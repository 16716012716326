// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qnIvtcFdH"];

const serializationHash = "framer-FPF9H"

const variantClassNames = {qnIvtcFdH: "framer-v-2yibux"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, gpExnKuXk: image ?? props.gpExnKuXk ?? {src: "https://framerusercontent.com/images/hJxaCYL0jpyU4kDOi5THdW35iJk.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/hJxaCYL0jpyU4kDOi5THdW35iJk.png?scale-down-to=512 512w, https://framerusercontent.com/images/hJxaCYL0jpyU4kDOi5THdW35iJk.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/hJxaCYL0jpyU4kDOi5THdW35iJk.png?scale-down-to=2048 2048w, https://framerusercontent.com/images/hJxaCYL0jpyU4kDOi5THdW35iJk.png 2560w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gpExnKuXk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qnIvtcFdH", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fit", intrinsicHeight: 773, intrinsicWidth: 2560, pixelHeight: 773, pixelWidth: 2560, sizes: "min(301px, 100vw)", ...toResponsiveImage(gpExnKuXk), ...{ positionX: "center", positionY: "center" }}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2yibux", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"qnIvtcFdH"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition>
</LayoutGroup>)

});

const css = [".framer-FPF9H[data-border=\"true\"]::after, .framer-FPF9H [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FPF9H.framer-1nsxemw, .framer-FPF9H .framer-1nsxemw { display: block; }", ".framer-FPF9H.framer-2yibux { height: 73px; overflow: hidden; position: relative; width: 301px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 73
 * @framerIntrinsicWidth 301
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gpExnKuXk":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerYVGE1RJRw: React.ComponentType<Props> = withCSS(Component, css, "framer-FPF9H") as typeof Component;
export default FramerYVGE1RJRw;

FramerYVGE1RJRw.displayName = "Todoist";

FramerYVGE1RJRw.defaultProps = {height: 73, width: 301};

addPropertyControls(FramerYVGE1RJRw, {gpExnKuXk: {__defaultAssetReference: "data:framer/asset-reference,hJxaCYL0jpyU4kDOi5THdW35iJk.png?originalFilename=Amazon_logo.svg.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerYVGE1RJRw, [])